.splash {
  &--img {
    width: 100%;
    //animation: splash 3.5s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

@keyframes splash {
  0% {
    transform: skew(0deg, 0deg);
  }
  20% {
    transform: skew(15deg, 0deg);
  }
  40% {
    transform: skew(30deg, 0deg);
  }
  60% {
    transform: skew(45deg, 0deg);
  }
  80% {
    transform: skew(60deg, 0deg);
  }
  100% {
    transform: skew(15deg, 0deg);
  }
}
