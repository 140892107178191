.img {
  &--disable {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  &--logo {
    box-shadow: 1px 1px 1px #222,0px 1px 1px #222,-1px 0px 1px #222;
    min-height: 60px;
    min-width: 60px;
    margin-right: 5px;
    //border-radius: 0 !important;
    img{
      object-fit: contain;
    }
  }
}
