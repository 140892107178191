body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 568px) {
  .banner-text {
    width: 98% !important;
    font-size: large;
  }
  .banner-container {
    /* border: 1px solid yellow !important; */
    height: 10%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Londrina Solid";
  src: url("../public/fonts/LondrinaSolid-Regular.ttf");
}

.londrina-solid-thin {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.londrina-solid-light {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.londrina-solid-regular {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.londrina-solid-black {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 900;
  font-style: normal;
}
